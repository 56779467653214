.alert-animate-shadow-pulse {
  animation: shadow-pulse 2s linear 0.2s 1;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 60px rgba(255, 215, 0, 0.3) inset;
  }
  40% {
    box-shadow: 0 0 0 60px rgba(255, 215, 0, 0.7) inset;
  }
  100% {
    box-shadow: 0 0 0 60px rgba(255, 215, 0, 0) inset;
  }

}