
.container {
  align-items: center;
  align-items: center;
  background: #5D5F9D;
  border-radius: 0.25rem;
  display: flex;
  display: flex;
  font-size: 0.875rem;
  height: 1.75rem;
  line-height: 1.25rem;
  padding: 2px 0.625rem;
  position: relative;
  white-space: nowrap;
  min-width: 0;
  gap: 0.5rem;
}

.deleteButton {
  align-items: center;
  display: flex;
  height: 1rem;
  justify-content: center;
  min-width: 1rem;
  padding: 0;
  width: 1rem;
}

.valueInput {
  font-size: 0.875rem !important;
}