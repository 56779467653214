.container {
  min-width: 0.3125rem; // min width so cursor is visible when input is focused
  position: relative;


  .input {
    // reset input styles
    background-color: transparent;
    border: none;
    bottom: 0;
    box-shadow: none;
    left: 0;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .defaultStyles {
    align-items: center;
    color: #FBFBFD;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    height: 1.5rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }

  .value {
    padding-right: 0.4375rem; // padding right as it's not possible to show a whitespace at the end of the text in the html as whitespace will be trimmed. We allow to enter only one whitespace at the end so it handles the case when user types a whitespace at the end of the text
    visibility: hidden;
  }

}
