.input {
  color-scheme: white;
  background: #484A7A !important;
  border-radius: 0.5rem !important;
  height: 3rem !important;
  border: 1px solid #6b7280 !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }


  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  &.fullWidth {
    width: 100%;
  }

  &.selected {
    outline: 2px solid #2e65a0 !important;
    outline-offset: 2px;
    box-shadow: none !important;
  }
}