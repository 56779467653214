.fieldContainer {
  align-items: center;
  background: transparent;
  border: .0625rem solid #484A7A;
  border-radius: 0.3125rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.375rem;
  min-height: 2.5rem; // if you update height -> you need to update variable in the component as based on the height we calculate how many lines of the content we have and change some other styles
  padding: 0.125rem 2.5rem .125rem .625rem;
  position: relative;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.075);
  width: 100%;
  min-width: 0;
  flex: 1;

  &.hasMoreThan1LineOfEmails {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }

  &.disabled {
    filter: opacity(0.5);
    pointer-events: none;
  }

  &:hover {
    border-color: #C9CADE;
  }

  &:focus-within {
    border-color: #C9CADE;
  }

  &.error {
    border-color: #dc2626;
  }
}

.input {
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 1.25rem;
}

.searchIcon {
  left: 0.75rem;
  position: absolute;
  top: 0.75rem;
}

.clearButton {
  align-items: center;
  color: var(--grays-base);
  display: flex;
  height: 1.25rem;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0.375rem;
  top: 0.6rem;
  width: 1.25rem;

  &:hover {
    color: var(--grays-cloudy);
  }
}
