@mixin blockBase {
  border: 1px solid #5D5F9D;
  padding: 0.625rem 1.5rem;
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    border-right: none;
  }

  &:last-child {
    border-radius: 0px 0.625rem 0.625rem 0px;
  }

  &:first-child {
    border-radius: 0.625rem 0px 0px 0.625rem;
  }
}

.topRow {
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
}

.block {
  @include blockBase;
  display: flex;
  flex-direction: column;

  &.blockBg {
    background-color: #5D5F9D;
  }

  @media screen and (max-width: 1000px) {

    &:first-child {
      width: 100%;
      border-radius: 0.625rem 0.625rem 0px 0px;
      border-right: 1px solid #5D5F9D;
      border-bottom: none;
    }

    &:nth-of-type(2) {
      border-radius: 0px 0px 0 0.625rem;
    }

    &:not(:first-child) {
      flex: 1;
    }

    &:last-child {
      border-radius: 0px 0px 0.625rem 0px;
    }
    
  }

  @media screen and (max-width: 760px) {
    padding: 0.625rem 0.5rem;
    
    &:not(:first-child) {
      flex: 0 0 50%;
    }

    &:nth-of-type(2), &:nth-of-type(3) {
      border-bottom: none;
      border-radius: 0;
      
    }

    &:nth-of-type(3) {
      border-right: 1px solid #5D5F9D;
    }

    &:nth-of-type(4) {
      border-radius: 0px 0px 0px 0.625rem;
    }

    
  }
}

.topItem {

  .title {
    color: #8183B3;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
    white-space: nowrap;
    align-items: end;
    display: flex;
    gap: 0.25rem;
  }

  .value {
    color: #FBFBFD;
    font-weight: 500;
    font-size: 1.875rem;
    white-space: nowrap;

    @media screen and (max-width: 1320px) {
      font-size: 1rem;
      line-height: 1.6875rem;
    }
  }

  .delta {
    border-radius: 0.3125rem;
    padding: 0.3125rem;
    height: 1.25rem;
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }
}


.bottomRow {
  display: flex;

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  @media screen and (max-width: 760px) {
    column-gap: 0.9375rem;
    row-gap: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid #5D5F9D;
    box-shadow: 0.625rem 0.625rem 1.875rem 0px rgba(7, 1, 31, 0.15), -0.375rem -0.375rem 1.875rem 0px rgba(97, 94, 255, 0.10);
    flex-wrap: wrap;
    padding: 0  0.625rem;
  }
}

.blockSmall {
  @include blockBase;
  padding: 0.625rem 1.25rem;

  @media screen and (max-width: 900px) {
    padding: 0.625rem;
    flex: 1;
  }

  @media screen and (max-width: 760px) {
    border: none;
    padding: 0.625rem 0;
    flex: 0 0 calc(calc(100% - (0.9375rem * 2)) / 3);
  }
}

.bottomItem {
  .title {
    color: #C9CADE;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    @media screen and (max-width: 420px) {
      font-size: .8125rem;
    }
  }

  .value {
    color: #FBFBFD;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.4375rem;

    @media screen and (max-width: 900px) {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
}