@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;200;300;400;500;600;700;800&display=swap');

@layer utilities {
  .no-scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .no-scrollbars::-webkit-scrollbar {
    display: none;
  }

  .header--link {
    @apply p-[0.25rem] text-[1.1rem] -tracking-[0.016em] md:p-[0.3rem] md:text-[1.2rem] lg:p-[0.5rem] lg:text-[1.625rem];
  }

  .home--xl {
    @apply text-[1.9rem] -tracking-[0.016em] md:text-[2.2rem] lg:text-[3rem] xl:text-[3.75rem];
  }
  .home--md {
    @apply leading-[1.2] text-[1.3rem] [word-spacing:0.02em] -tracking-[0.016em] md:text-[1.5rem] lg:text-[1.7rem] xl:text-[1.875rem];
  }
  .home--icon {
    @apply h-[2rem] text-teal-01 md:h-[2.5rem] lg:h-[3.2rem] xl:h-[4rem];
  }
  .home--bullets {
    @apply leading-[1.2] text-[1.1rem] [word-spacing:0.02em] -tracking-[0.016em] md:text-[1.3rem] lg:text-[1.5rem] xl:text-[1.7rem];
  }

  .signup--input {
    @apply p-[0.9rem] text-[0.9rem] md:p-[1rem] md:text-[1rem] lg:p-[1.2rem] lg:text-[1.2rem] xl:p-[1.5rem] xl:text-[1.5rem];
  }

  .signup-button {
    @apply font-[500] px-[3rem] py-[0.6rem] text-[1.2rem] -tracking-[0.016em] md:px-[3.5rem] md:py-[0.7rem] md:text-[1.35rem] lg:px-[5rem] lg:text-[1.625rem] xl:px-[6rem] xl:py-[1rem];
  }

  .grid--spacing {
    @apply gap-[2.5rem] px-[0.1rem] py-[4rem] sm:gap-[1.4rem] sm:px-[0.1rem] sm:py-[6rem] md:gap-[1.8rem] md:px-[0.2rem] md:py-[7rem] lg:gap-[2rem] lg:px-[1rem] lg:py-[9rem] xl:px-[2rem] xl:py-[12rem];
  }
  .grid--header {
    @apply leading-[1.1] text-[1.9rem] -tracking-[0.016em] sm:text-[2.5rem] md:text-[3rem] lg:text-[3.9rem] xl:text-[4.5rem];
  }
  .grid--underline {
    @apply h-[0.5rem] -mt-[0.5rem] md:h-[0.7rem] md:-mt-[0.7rem] lg:h-[1.1rem] lg:-mt-[1.1rem] xl:h-[1.25rem] xl:-mt-[1.25rem];
  }
  .grid--content {
    @apply leading-[1.15] space-y-[1em] text-[1.1rem] sm:text-[1.3rem] md:text-[1.4rem] lg:text-[1.6rem] xl:text-[1.8rem];
  }

  .about--content {
    @apply leading-[1.35] text-[1.1rem] -tracking-[0.016em] sm:text-[1.2rem] md:text-[1.3rem] lg:text-[1.6rem] xl:text-[1.8rem];
  }

  .section--spacing {
    @apply px-[1rem] sm:px-[2rem] md:px-[2.5rem] lg:px-[3.5rem] xl:px-[4.5rem];
  }

  .content--spacing {
    @apply py-[4rem] space-y-[1.5rem] sm:py-[6rem] md:py-[7rem] md:space-y-[1.7rem] lg:py-[9rem] lg:space-y-[2rem] xl:py-[12rem] xl:space-y-[2.5rem];
  }
  .content--allcaps {
    @apply text-[1rem] tracking-[0.25em] sm:text-[1.1rem] md:text-[1.2rem] lg:text-[1.35rem] xl:text-[1.4rem];
  }
  .content--header {
    @apply leading-[1.05] text-[2rem] -tracking-[0.016em] sm:text-[2.8rem] md:text-[3.3rem] lg:text-[4rem] xl:text-[4.5rem];
  }
  .content--body {
    @apply leading-[1.25] text-[1.2rem] tracking-[0.018em] [word-spacing:0.025em] sm:text-[1.5rem] md:text-[1.8rem] lg:text-[2rem] xl:text-[2.2rem];
  }

  /* -- leave element on page but remove visually, adapted from https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html -- */
  .visually-hidden:not(:focus):not(:active) {
    @apply absolute h-px overflow-hidden w-px whitespace-nowrap;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
  }
}

:root {
  --header-height: 5rem;
}

/* -- remove number spinners -- */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
/* -- */

/* -- remove autofill background in webkit browsers -- */
input:-webkit-autofill {
  -webkit-background-clip: text;
}
/* -- */


/* -- react toastify styles -- */
.react-tooltip {
  --rt-color-dark: #0a0b11;
  border-radius: 0.5rem !important;
}
/* -- */

/* update global scrollbar styles */

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  padding: 0 2px;
}

*::-webkit-scrollbar-track {
  background: #484A7A;
  width: 7px;
  border-radius: 10px;
  
}

*::-webkit-scrollbar-thumb {
  width: 3px;
  border-radius: 10px;
  background:  #A5A6C9;
  border: 2px solid #484A7A;
  cursor: pointer;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}

.sb-show-main {
  /* Storybook style */
  background: #252641;
  color: #fff;
}