
/* Toast styles */
.Toastify {
  --toastify-toast-width: auto; 
  
}
.alert-toast-container {
  --toastify-toast-top: 5rem;
  --toastify-toast-top: 5rem;
  --toastify-toast-right: 1rem;
  transition: top 0.25s ease-in-out;
  padding-right: 0 !important;
  padding-top: 0 !important; 
  top: var(--toastify-toast-top) !important;

}

.alert-toast-container--multiple {
  --toastify-toast-top: 8.625rem;
  --toastify-toast-top: 8.625rem;
  top: var(--toastify-toast-top) !important;

}

.an-slide-in-right {
	animation: slide-in-right 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.an-slide-out-right {
	animation: slide-out-right 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(1000px);
    opacity: 0;
  }
}
